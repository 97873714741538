import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import Slider from "@react-native-community/slider";
import { Audio } from "expo-av";
import { AntDesign, Foundation, FontAwesome } from "@expo/vector-icons"; // You can choose a different icon library
import { URLS } from "../utils/constants";

const AudioPlayer = ({ audioUri, shouldPlay = false }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1.0);
  const [rate, setRate] = useState(1.0);
  const [sound, setSound] = useState();
  const uri = `${URLS.AUDIO}/${audioUri}`;

  const updatePlaybackStatus = async (status) => {
    // console.log("Status", status);
    if (status.didJustFinish) {
      await sound.setPositionAsync(0);
      await sound.pauseAsync();
      setIsPlaying(false);
      setPosition(0);
    } else {
      // Update the position continuously while playing
      setPosition(status.positionMillis);
    }
  };

  useEffect(() => {
    async function loadAudio() {
      try {
        console.log("Maaiz loadingAudio Uri:", uri);
        setIsPlaying(shouldPlay);
        const { sound, status } = await Audio.Sound.createAsync(
          { uri: uri },
          { shouldPlay: shouldPlay }
        );
        setSound(sound);
        setDuration(status.durationMillis);
      } catch (err) {
        console.log("Maaiz err", err);
      }
    }

    loadAudio();

    return () => {
      if (sound) {
        sound.unloadAsync();
      }
    };
  }, []);

  useEffect(() => {
    // This effect handles the playback status updates
    if (sound) {
      sound.setOnPlaybackStatusUpdate(updatePlaybackStatus);
    }
  }, [sound]);

  const togglePlayPause = async () => {
    if (isPlaying) {
      await sound.pauseAsync();
    } else {
      await sound.playAsync();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return formattedTime;
  };

  const handleSeek = async (value) => {
    await sound.setPositionAsync(value * duration);
    setPosition(value * duration);
  };

  const onVolumeChange = (value) => {
    sound.setVolumeAsync(value);
    setVolume(value);
  };

  const onRateChange = (value) => {
    sound.current.setRateAsync(value, true);
    setRate(value);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.playButton} onPress={togglePlayPause}>
        {/* <AntDesign
          name={isPlaying ? "pause" : "play"}
          size={25}
          color="black"
        /> */}
        <FontAwesome
          name={isPlaying ? "pause" : "play"}
          size={20}
          color="black"
        />
      </TouchableOpacity>

      <Text style={styles.time}>
        {formatTime(position)} / {formatTime(duration)}
      </Text>

      <Slider
        style={styles.slider}
        value={position}
        maximumValue={duration}
        onValueChange={handleSeek}
        minimumTrackTintColor="#007BFF"
        maximumTrackTintColor="#000000"
      />

      {/* <TouchableOpacity
        onPress={() => onVolumeChange(volume === 1.0 ? 0.0 : 1.0)}
      >
        <FontAwesome
          name={volume === 1.0 ? "volume-up" : "volume-off"}
          size={20}
          color="black"
        />
      </TouchableOpacity>

      <TouchableOpacity onPress={() => onRateChange(rate === 1.0 ? 1.5 : 1.0)}>
        <AntDesign
          name={rate === 1.0 ? "fastforward" : "fastbackward"}
          size={24}
          color="black"
        />
      </TouchableOpacity> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",

    paddingTop: 15,
    width: "100%",
  },
  playButton: {
    paddingHorizontal: 10,
  },
  playIcon: {
    width: 60,
    height: 60,
  },
  sliderContainer: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
  },
  time: {
    fontSize: 12,
  },
  slider: {
    flex: 3,
  },
  controlLabel: {
    marginTop: 20,
    fontSize: 14,
  },
});

export default AudioPlayer;
