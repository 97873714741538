import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { URLS } from "../utils/constants";

const HTML5AudioPlayer = ({ audioUri, shouldPlay = false }) => {
  const uri = `${URLS.AUDIO}/${audioUri}`;

  return (
    // Render HTML5 audio tag for web
    <View style={styles.container}>
      <audio
        controls
        style={styles.audio}
        preload="metadata"
        autoPlay={shouldPlay}
      >
        <source src={uri} type="audio/wav" />
        Your browser does not support the audio tag.
      </audio>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingVertical: 15,
    width: "100%",
  },
  audio: {
    width: "90%",
  },
});

export default HTML5AudioPlayer;
