import { createContext, useState, useContext, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { STORAGE_KEYS } from "../utils/constants";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const { i18n } = useTranslation();
  // const [settings, setSettings] = useState({
  //   locale: "en-US", // Default language is English (you can change this)
  //   language: "English",
  //   notification: true, // Default notification setting
  //   // Add more settings here
  // });

  const [settings, setSettings] = useState({
    language: i18n.resolvedLanguage, // Setting default language to i18n resolved language
    notifications: true, // Default notifications setting
    // Add other settings as needed
  });

  // TODO: Maaiz uncomment below code after testing settings
  // Loading settings from cache
  // useEffect(() => {
  //   async function loadSettings() {
  //     try {
  //       const storedSettingsJSON = await AsyncStorage.getItem(STORAGE_KEYS.APP_SETTINGS);
  //       if (storedSettingsJSON) {
  //         const storedSettings = JSON.parse(storedSettingsJSON);
  //         setSettings({ ...settings, ...storedSettings });
  //       }
  //     } catch (error) {
  //       console.error("Error loading settings:", error);
  //     }
  //   }

  //   loadSettings();
  // }, []);

  const updateSetting = async (key, value) => {
    try {
      const newSettings = { ...settings, [key]: value };
      await AsyncStorage.setItem(
        STORAGE_KEYS.APP_SETTINGS,
        JSON.stringify(newSettings)
      );
      setSettings(newSettings);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSetting }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
