import { useState, useEffect } from "react";

import { SettingsProvider } from "./src/contexts/SettingsContext";
import AppNavigator from "./src/components/AppNavigator";
import { initializeI18n } from "./src/services/i18n";
import "./src/services/firebase";
import { Platform } from "react-native";
import { customLogEvent } from "./src/utils/analyticsHelpers";

export default function App() {
  const [i18nInitialized, setI18nInitialized] = useState(false);

  useEffect(() => {
    const initializeApp = async () => {
      await initializeI18n();
      setI18nInitialized(true);
    };
    customLogEvent("app_launch", { platform: Platform.OS });

    initializeApp();
  }, []);

  if (!i18nInitialized) {
    // TODO: Maaiz render a loading indicator or nothing until i18n is initialized
    return null;
  }

  return (
    <SettingsProvider>
      <AppNavigator />
    </SettingsProvider>
  );
}
