import React from "react";
import { Platform } from "react-native";
import MobileAudioPlayer from "./MobileAudioPlayer";
import WebAudioPlayer from "./WebAudioPlayer";
import HTML5AudioPlayer from "./HTML5AudioPlayer";

const AudioPlayer = ({ audioUri, shouldPlay }) => {
  return Platform.OS === "web" ? (
    // <WebAudioPlayer audioUri={audioUri} shouldPlay={shouldPlay} />
    <HTML5AudioPlayer audioUri={audioUri} shouldPlay={shouldPlay} />
  ) : (
    <MobileAudioPlayer audioUri={audioUri} shouldPlay={shouldPlay} />
  );
};

export default AudioPlayer;
