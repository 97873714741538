import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  initializeAuth,
  getReactNativePersistence,
  browserLocalPersistence,
} from "firebase/auth";

export const getAnalyticsInstance = (app) => {
  const analytics = getAnalytics(app);
  return analytics;
};

export const getAuthInstance = (app) => {
  const auth = initializeAuth(app, {
    persistence: browserLocalPersistence,
  });
  return auth;
};
