import { Platform } from "react-native";
import { logEvent, setUserId } from "firebase/analytics";

import { analytics } from "../services/firebase";
import { getUserIdFromCache } from "./helpers";
import { ANALYTICS_ENABLED } from "./constants";

const logScreenView = (screenName, params) => {
  customLogEvent("screen_view", {
    name: screenName,
    ...params,
  });
};

const customLogEvent = (eventName, params) => {
  if (!ANALYTICS_ENABLED) {
    return;
  }
  try {
    if (Platform.OS === "web") {
      logEvent(analytics, eventName, params);
      console.log(
        `Event ${eventName} logged with params:${JSON.stringify(params)}`
      );
    } else {
      analytics().logEvent(eventName, params);
      console.log(
        `Event ${eventName} logged with params:${JSON.stringify(params)}`
      );
    }
  } catch (error) {
    console.error("Error logging event:", error);
  }
};

const logLogin = (userId) => {
  try {
    if (Platform.OS === "web") {
      setUserId(analytics, userId);
      console.log(`User logged in with ID: ${userId}`);
    } else {
      analytics().setUserId(userId);
      console.log(`User logged in with ID: ${userId}`);
    }
  } catch (error) {
    console.error("Error logging login:", error);
  }
};

const logLogout = () => {
  try {
    if (Platform.OS !== "web") {
      analytics().setUserId(null);
      console.log("User logged out");
    }
  } catch (error) {
    console.error("Error logging logout:", error);
  }
};

const logApiRequest = async (endpoint, method, action) => {
  const userId = await getUserIdFromCache();
  customLogEvent("api_request_sent", {
    endpoint,
    method,
    user_id: userId,
    action,
  });
};

const logApiError = async (endpoint, statusCode, errorMessage, action) => {
  const userId = await getUserIdFromCache();
  customLogEvent("api_error", {
    endpoint,
    status_code: statusCode,
    error_message: errorMessage,
    user_id: userId,
    action,
  });
};

// Add more helper functions as needed

export {
  logScreenView,
  customLogEvent,
  logLogin,
  logLogout,
  logApiRequest,
  logApiError,
};
