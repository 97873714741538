// FIXME: Work on this when backend is in production as it not working
// with ngrok as ngrok is giving warning page
//www.npmjs.com/package/howler

import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import Slider from "@react-native-community/slider";
import { Howl } from "howler";
import { FontAwesome } from "@expo/vector-icons";
import { URLS } from "../utils/constants";

const WebAudioPlayer = ({ audioUri, shouldPlay = false }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const [sound, setSound] = useState();
  const uri = `${URLS.AUDIO}/${audioUri}`;

  useEffect(() => {
    async function loadAudio() {
      try {
        console.log("Maaiz loadAudio uri", uri);
        const howl = new Howl({
          src: [uri],
          html5: true,
          format: ["wav"],
          onload: () => {
            setDuration(howl.duration() * 1000);
            if (shouldPlay) {
              sound.play();
              setIsPlaying(true);
            }
          },
          onend: () => {
            setIsPlaying(false);
            setPosition(0);
          },
          onseek: () => {
            setPosition(howl.seek() * 1000);
          },
        });

        setSound(howl);
      } catch (err) {
        console.log("Error loading audio:", err);
      }
    }

    loadAudio();

    return () => {
      if (sound) {
        sound.unload();
      }
    };
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      sound.pause();
    } else {
      sound.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSeek = (value) => {
    const seekTimeInSeconds = (value * duration) / 1000;
    sound.seek(seekTimeInSeconds);
    setPosition(value * duration);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.playButton} onClick={togglePlayPause}>
        <FontAwesome
          name={isPlaying ? "pause" : "play"}
          size={20}
          color="black"
        />
      </TouchableOpacity>

      <Text style={styles.time}>
        {formatTime(position)} / {formatTime(duration)}
      </Text>

      <Slider
        style={styles.slider}
        value={position}
        maximumValue={duration}
        onValueChange={handleSeek}
        minimumTrackTintColor="#007BFF"
        maximumTrackTintColor="#000000"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingVertical: 15,
    width: "100%",
  },
  playButton: {
    paddingHorizontal: 10,
  },
  time: {
    fontSize: 12,
  },
  slider: {
    flex: 3,
  },
});

export default WebAudioPlayer;
