import React, { useEffect } from "react";
import { Image, View, Text, StyleSheet, ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";

const LogoImage = () => (
  <Image
    source={require("../../assets/cropsea-logo.png")}
    style={styles.logoImage}
  />
);

const PrivacyPolicyScreen = () => {
  const navigation = useNavigation();

  useEffect(() => {
    const isComingDirectly = navigation.getState().index === 0;
    if (isComingDirectly) {
      navigation.setOptions({
        headerLeft: LogoImage,
      });
    }
  });

  return (
    <ScrollView style={styles.container}>
      <View>
        <Text style={styles.header}>Cropsea Privacy Policy</Text>

        <Text style={styles.sectionHeader}>1. Information We Collect:</Text>
        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>1.1 Personal Information:</Text>
          {"\n"}
          When you use our app, Grainogreen Agtech Private Ltd (hereinafter
          referred to as "the Company") collects personal information such as
          your phone number through Firebase phone authentication.
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>1.2 Voice Input:</Text>
          {"\n"}
          The app, known as CropSea, uses the microphone for voice inputs. Your
          voice data may be processed to understand and respond to your queries.
          We do not store raw voice recordings on our servers.
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>1.3 Location Information:</Text>
          {"\n"}
          CropSea may request and use location information to provide
          location-based services, such as weather updates and crop information.
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>1.4 Device Information:</Text>
          {"\n"}
          We may collect information about your device, including device type,
          operating system, and unique device identifiers.
        </Text>

        <Text style={styles.sectionHeader}>
          2. How We Use Your Information:
        </Text>
        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>2.1 Authentication:</Text>
          {"\n"}
          The Company uses your phone number for authentication purposes to
          ensure the security of your account.
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>2.2 Service Improvement:</Text>
          {"\n"}
          Your voice inputs may be used to improve the accuracy and
          functionality of CropSea's voice AI chatbot.
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>
            2.3 Location-Based Services:
          </Text>
          {"\n"}
          Location information may be used to provide localized content and
          relevant information based on your geographical location.
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>2.4 Personalization:</Text>
          {"\n"}
          We may use collected information to personalize your CropSea
          experience, including providing tailored content and recommendations.
        </Text>

        <Text style={styles.sectionHeader}>3. Information Sharing:</Text>
        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>3.1 Third-Party Services:</Text>
          {"\n"}
          We may share information with third-party services for analytics, user
          engagement, and other purposes to enhance your experience.
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>3.2 Legal Requirements:</Text>
          {"\n"}
          The Company may disclose your information if required by law or in
          response to a legal request.
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.subsectionHeader}>3.3 Business Transfers:</Text>
          {"\n"}
          In the event of a merger, acquisition, or sale of all or part of our
          assets, your information may be transferred.
        </Text>

        <Text style={styles.sectionHeader}>4. Security:</Text>
        <Text style={styles.paragraph}>
          We take reasonable measures to protect your information from
          unauthorized access or disclosure.
        </Text>

        <Text style={styles.sectionHeader}>5. Your Choices:</Text>
        <Text style={styles.paragraph}>
          You can control permissions for the microphone, location services, and
          other features in your device settings.
        </Text>

        <Text style={styles.sectionHeader}>6. Access and Deletion:</Text>
        <Text style={styles.paragraph}>
          You have the right to access and request deletion of your personal
          information. Contact us for assistance.
        </Text>

        <Text style={styles.sectionHeader}>7. Changes to Privacy Policy:</Text>
        <Text style={[styles.paragraph, styles.lastParagraph]}>
          The Company may update its Privacy Policy to reflect changes in
          practices. Check for updates regularly.
        </Text>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  logoImage: {
    width: 100,
    height: 100,
    marginLeft: 10,
    marginTop: 4,
    resizeMode: "contain",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 15,
    marginBottom: 10,
  },
  subsectionHeader: {
    fontWeight: "bold",
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 15,
  },
  lastParagraph: {
    marginBottom: 40,
  },
});

export default PrivacyPolicyScreen;
