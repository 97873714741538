// TODO: Maaiz cleanup and fix this file
import { useState, useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Linking from "expo-linking";

import ChatScreen from "../screens/ChatScreen";
import SettingsScreen from "../screens/SettingsScreen";
import AuthScreen from "../screens/AuthScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import { importData, fetchAllItems, getAccessToken } from "../utils/helpers";

import { STORAGE_KEYS } from "../utils/constants";
// import AuthScreenRN from "../screens/AuthScreenRN";
// import AuthScreenExpoRN from "../screens/AuthScreenExpoRN";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Image, StyleSheet } from "react-native";
import TermsOfServiceScreen from "../screens/TermsOfServiceScreen";

const Stack = createStackNavigator();

const LogoImage = () => (
  <Image
    source={require("../../assets/cropsea-logo.png")}
    style={styles.logoImage}
  />
);

// Handle deep linking for web
const prefix = Linking.createURL("/");
const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      Settings: "settings",
      Login: "login",
      ChatScreen: "chat",
      PrivacyPolicy: "privacypolicy",
      TermsOfService: "termsofservice",
    },
  },
};

const AppNavigator = () => {
  // TODO: Maaiz change it to load this info from server
  const [isLanguageSet, updateIsLanguageSet] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loadNavigator, setLoadNavigator] = useState(false);
  const { t } = useTranslation();

  // useEffect(() => {
  //   // AsyncStorage.clear();
  //   // Collect the location when the user first visits the app
  //   const getAllAsyncStorage = async () => {
  //     // await AsyncStorage.removeItem(STORAGE_KEYS.APP_SETTINGS);
  //     // await AsyncStorage.removeItem(STORAGE_KEYS.USER_NAME);
  //     await importData();
  //     // const itmes = await fetchAllItems();
  //     // console.log("Maaiz AsyncStorage Items:", itmes);
  //   };

  //   getAllAsyncStorage();
  // }, []);

  useEffect(() => {
    (async function () {
      // Check if the language is already set
      let settings = await AsyncStorage.getItem(STORAGE_KEYS.APP_SETTINGS);
      console.log("Maaiz settings str", settings);
      if (settings) {
        settings = JSON.parse(settings);
        console.log("Maaiz settings", settings, settings.language);
        if (settings.language) {
          updateIsLanguageSet(true);
        }
      }

      // Check authentication state when the app starts
      let unsubscribe;
      const checkAuthentication = async () => {
        const auth = getAuth();
        console.log("Maaiz in AppNavigator auth", auth);

        unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            // If the token is refreshed by firebase in the background(when
            // the token expires). token in Asyncstore need to be updated
            const firebaseToken = await user.getIdToken();
            // const accessToken = await AsyncStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
            // if (firebaseToken !== accessToken) {
            await AsyncStorage.setItem(
              STORAGE_KEYS.ACCESS_TOKEN,
              firebaseToken
            );
            // }

            setIsAuthenticated(!!user);
          } else {
            console.log("Not Logged In");
          }
          setLoadNavigator(true);
        });
      };
      checkAuthentication();

      return () => {
        unsubscribe();
      };
    })();
  }, []);

  console.log("Maaiz isLanguageSet", isLanguageSet);
  console.log("Maaiz isAuthenticated", isAuthenticated);

  return (
    <NavigationContainer linking={linking}>
      {loadNavigator && (
        <Stack.Navigator
          screenOptions={{
            cardStyle: { backgroundColor: "#F9F9F9" }, // Set the background color here
          }}
          // initialRouteName="ChatScreen"
          initialRouteName={
            isLanguageSet
              ? isAuthenticated
                ? "ChatScreen"
                : "Login"
              : "Settings"
          }
        >
          <Stack.Screen
            name="Settings"
            component={SettingsScreen}
            options={{
              title: `${t("navigation.settings")}`,
              headerTitleAlign: "center",
              headerTitleStyle: { fontWeight: "bold" },
            }}
          />
          <Stack.Screen
            name="Login"
            component={AuthScreen}
            options={{
              title: `${t("navigation.login")}`,
              headerTitleAlign: "center",
              headerTitleStyle: { fontWeight: "bold" },
              headerLeft: LogoImage,
            }}
          />
          <Stack.Screen
            name="ChatScreen"
            component={ChatScreen}
            options={{
              title: `${t("navigation.cropsea")}`,
              headerTitleAlign: "center",
              headerTitleStyle: { fontWeight: "bold" },
              headerLeft: LogoImage,
            }}
          />
          <Stack.Screen
            name="PrivacyPolicy"
            component={PrivacyPolicyScreen}
            options={{
              title: "Privacy Policy",
              headerTitleAlign: "center",
              headerTitleStyle: { fontWeight: "bold" },
            }}
          />
          <Stack.Screen
            name="TermsOfService"
            component={TermsOfServiceScreen}
            options={{
              title: "Terms of Service",
              headerTitleAlign: "center",
              headerTitleStyle: { fontWeight: "bold" },
            }}
          />
          {/* Add more screens for your app */}
        </Stack.Navigator>
      )}
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  logoImage: {
    width: 100,
    height: 100,
    marginLeft: 10,
    marginTop: 4,
    resizeMode: "contain",
  },
});

export default AppNavigator;
