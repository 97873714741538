// TODO: Maaiz figure out how to read SMS and autofill OTP
// TODO: Improve this component, for now it is too cluttered
// https://medium.com/@salasjoshua/phone-authentication-with-firebase-for-expo-managed-workflow-app-fdcba398f474

import { useState, useRef, useEffect } from "react";
import {
  Text,
  View,
  TextInput,
  Button,
  StyleSheet,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Modal,
  KeyboardAvoidingView,
} from "react-native";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import { initializeApp, getApp } from "firebase/app";
import {
  getAuth,
  initializeAuth,
  browserLocalPersistence,
  PhoneAuthProvider,
  signInWithCredential,
  getReactNativePersistence,
  getAdditionalUserInfo,
  RecaptchaVerifier,
} from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useTranslation } from "react-i18next";
import { Ionicons } from "@expo/vector-icons";

import FirebaseRecaptchaVerifierModal from "../components/firebase-recaptcha/modal";
import { useSettings } from "../contexts/SettingsContext";
import { addCountryCode, executeWithDelay } from "../utils/helpers";

import { URLS, STORAGE_KEYS } from "../utils/constants";
import { auth, app } from "../services/firebase";
import { logScreenView } from "../utils/analyticsHelpers";
import { customLogEvent } from "../utils/analyticsHelpers";
import { removeLocationFromCache } from "../services/localtionService";

const delay = 3;
let timer;

const AuthScreen = () => {
  // Ref or state management hooks
  const recaptchaVerifierRef = useRef(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [verificationId, setVerificationId] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const { settings } = useSettings();
  const navigation = useNavigation();
  const { t } = useTranslation();

  const firebaseConfig = app ? app.options : undefined;
  const [message, setMessage] = useState();
  const attemptInvisibleVerification = true;
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  // Setting language for recaptcha and authentication message
  auth.languageCode = settings.language || "en";

  useEffect(() => {
    // Log analytics event
    logScreenView("AuthScreen");
  }, []);
  // useEffect(() => {
  //   // Set up a listener for authentication state changes
  //   const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
  //     if (user) {
  //       // User is signed in
  //       console.log("Current user:", user);
  //     } else {
  //       // User is signed out
  //       console.log("User is signed out");
  //     }
  //   });

  //   // Clean up the listener when the component unmounts
  //   return () => unsubscribe();
  // }, [firebaseAuth]);

  const handleSendVerificationCode = async () => {
    let verifier;
    // The FirebaseRecaptchaVerifierModal ref implements the
    // FirebaseAuthApplicationVerifier interface and can be
    // passed directly to `verifyPhoneNumber`.
    try {
      setIsLoading(true);
      let phoneNumberWithCountryCode = addCountryCode(phoneNumber);
      const phoneProvider = new PhoneAuthProvider(auth);
      let verificationId;

      // Log authentication metrics
      customLogEvent("number_of_attempts", {
        action: "send_verification_code",
      });

      if (Platform.OS === "web") {
        verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
          size: "invisible",
        });
        verificationId = await phoneProvider.verifyPhoneNumber(
          phoneNumberWithCountryCode,
          verifier
        );
      } else {
        verificationId = await phoneProvider.verifyPhoneNumber(
          phoneNumberWithCountryCode,
          recaptchaVerifierRef.current
        );
      }

      setVerificationId(verificationId);
      setIsErrorMessage(false);
      setMessage(t("login.verificationCodeSent"));
      showModalWithDuration(setSuccessModalVisible);

      // Simulate API call (replace with actual logic)
      // setTimeout(() => {
      //   setIsLoading(false);
      //   showModalWithDuration(setSuccessModalVisible);
      // }, 2000);

      // Log additional event for successful verification code sent
      customLogEvent("verification_code_sent", {
        action: "send_verification_code",
      });
    } catch (err) {
      console.log("Maaiz Error:", err);

      setIsErrorMessage(true);
      setMessage(`Error: ${err.message}`);
      showModalWithDuration(setErrorModalVisible);

      // Log additional event for failed verification code sent
      customLogEvent("failed_attempts", {
        action: "send_verification_code",
        error: err.message,
      });
    } finally {
      setIsLoading(false);
      if (verifier) {
        verifier.clear();
      }
    }
  };

  const handleConfirmVerificationCode = async () => {
    try {
      setIsLoading(true);
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      // Sign in with the credential
      const userCredential = await signInWithCredential(auth, credential);

      await sendDataToServer(userCredential);
      // Adding user name to async store
      const firebaseToken = await userCredential.user.getIdToken();
      await AsyncStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, firebaseToken);
      AsyncStorage.setItem(STORAGE_KEYS.USER_NAME, name);
      AsyncStorage.setItem(STORAGE_KEYS.USER_ID, userCredential.user.uid);

      setIsErrorMessage(false);
      setMessage(t("login.loginSuccess"));
      showModalWithDuration(setSuccessModalVisible);
      executeWithDelay(() => navigation.replace("ChatScreen"), 3);

      // Log additional event for successful verification code confirmation
      customLogEvent("verification_code_confirmed", {
        userId: userCredential.user.uid,
      });
    } catch (err) {
      setIsErrorMessage(true);
      console.log("Maaiz here:", err.message);
      setMessage(t("login.loginfailed"));
      showModalWithDuration(setErrorModalVisible);

      // Log authentication metrics for failed attempt
      customLogEvent("failed_attempts", {
        action: "confirm_verification_code",
        error: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const sendDataToServer = async (userCredential) => {
    console.log("Maaiz in sendDataToServer userCredential", userCredential);
    // Data to send to the backend

    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    console.log("Maaiz additionalUserInfo", additionalUserInfo);
    // Check if the user is new
    const isNewUser = additionalUserInfo.isNewUser;
    console.log("Maaiz is newuser:", isNewUser);

    // Send to server only when it is new user
    if (isNewUser) {
      //Removing location from store if it a new user and
      // the location is already in cache
      await removeLocationFromCache();
      const userId = userCredential.user.uid;
      const data = {
        userId: userId,
        username: name,
        phoneNumber: phoneNumber,
      };

      console.log("Maaiz userCredential.user", userCredential.user);
      // Get the Firebase token
      const firebaseToken = await userCredential.user.getIdToken();

      // Store the Firebase token in AsyncStorage

      console.log("Maaiz firebasetoken:", firebaseToken);
      let response;
      try {
        customLogEvent("api_request_sent", {
          endpoint: URLS.USERS,
          method: "POST",
          request_data: { user_id: userId },
        });
        response = await axios.post(URLS.USERS, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${firebaseToken}`, // Include the Firebase token as a Bearer token
          },
        });
      } catch (error) {
        customLogEvent("api_error", {
          endpoint: URLS.USERS,
          status_code: response && response.status,
          error_message: error.message,
        });
      }
    }
  };

  const showModalWithDuration = (
    modalSetter,
    duration = 3000 // 3 seconds by default
  ) => {
    modalSetter(true);
    setTimeout(() => modalSetter(false), duration);
  };

  const closeModal = () => {
    setSuccessModalVisible(false);
    setErrorModalVisible(false);
  };

  return (
    <View style={styles.container}>
      {Platform.OS === "web" ? (
        <div id="recaptcha-container"></div>
      ) : (
        <FirebaseRecaptchaVerifierModal
          ref={recaptchaVerifierRef}
          firebaseConfig={firebaseConfig}
          attemptInvisibleVerification={true}
          androidHardwareAccelerationDisabled
          androidLayerType="software" // This and above are need for this issue https://github.com/expo/expo/issues/17912
          // appVerificationDisabledForTesting={true} // Do this only for Dev mode
        />
      )}
      {/* Form for Collecting User Details */}
      <View style={styles.formContainer}>
        <Text style={styles.label}>{t("login.name")}</Text>
        <TextInput
          style={styles.input}
          value={name}
          onChangeText={(text) => setName(text)}
          editable={!isLoading} // Disable input when loading
          autoFocus
        />
        <Text style={styles.label}>{t("login.phoneNumber")}</Text>
        <TextInput
          style={styles.input}
          value={phoneNumber}
          autoCompleteType="tel"
          keyboardType="phone-pad"
          textContentType="telephoneNumber"
          onChangeText={(phoneNumber) => setPhoneNumber(phoneNumber)}
          editable={!isLoading} // Disable input when loading
        />
        <TouchableOpacity
          style={[
            styles.button,
            (!name || !phoneNumber || isLoading) && styles.disabledButton,
          ]}
          onPress={handleSendVerificationCode}
          // disabled={true}
          disabled={!name || !phoneNumber || isLoading}
        >
          <Text style={styles.buttonText}>
            {t("login.sendVerificationCode")}
          </Text>
        </TouchableOpacity>
      </View>

      {/* Form for Confirming Verification Code */}
      <View style={styles.formContainer}>
        <Text style={styles.label}>{t("login.verificationCode")}</Text>
        <TextInput
          style={styles.input}
          value={verificationCode}
          onChangeText={(text) => setVerificationCode(text)}
          keyboardType="numeric"
          editable={!!verificationId && !isLoading}
          autoComplete="sms-otp"
          textContentType="oneTimeCode"
        />
        <TouchableOpacity
          style={[
            styles.button,
            (!verificationId || isLoading) && styles.disabledButton,
          ]}
          onPress={handleConfirmVerificationCode}
          disabled={!verificationId || isLoading}
        >
          <Text style={styles.buttonText}>
            {t("login.confirmVerificationCode")}
          </Text>
        </TouchableOpacity>
      </View>
      {/* {attemptInvisibleVerification && <FirebaseRecaptchaBanner />} */}

      {/* Overlay/Modal for Success Message */}
      <Modal
        transparent={true}
        animationType="slide"
        visible={successModalVisible}
        onRequestClose={() => setSuccessModalVisible(false)}
      >
        <TouchableWithoutFeedback onPress={closeModal}>
          <View style={styles.overlay}>
            <View
              style={[
                styles.modalContent,
                styles.bottomModal,
                { backgroundColor: "#4CAF50" },
              ]}
            >
              <Text style={styles.overlayText}>{message}</Text>
              <TouchableOpacity
                style={styles.closeIconContainer}
                onPress={() => setSuccessModalVisible(false)}
              >
                {/* Close Icon using Ionicons */}
                <Ionicons name="checkmark-circle" size={24} color="#fff" />
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>

      {/* Overlay/Modal for Error Message */}
      <Modal
        transparent={true}
        animationType="slide"
        visible={errorModalVisible}
        onRequestClose={() => setErrorModalVisible(false)}
      >
        <TouchableWithoutFeedback onPress={closeModal}>
          <View style={styles.overlay}>
            <View
              style={[
                styles.modalContent,
                styles.bottomModal,
                { backgroundColor: "#F44336" },
              ]}
            >
              <Text style={[styles.overlayText, { color: "#fff" }]}>
                {message}
              </Text>
              <TouchableOpacity
                style={styles.closeIconContainer}
                onPress={() => setErrorModalVisible(false)}
              >
                {/* Close Icon using Ionicons */}
                <Ionicons name="close-circle" size={24} color="#fff" />
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>

      {/* Loading Overlay */}
      {isLoading && (
        <View style={styles.loadingOverlay}>
          <ActivityIndicator size="large" color="#fff" />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    padding: 20,
    backgroundColor: "#F5F5F5",
  },
  formContainer: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
    maxWidth: 400, // Set a maximum width for better responsiveness
    width: "100%", // Take the full width available
    marginHorizontal: "auto", // Center the form horizontally
  },
  label: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
    color: "#333",
  },
  input: {
    height: 40,
    borderColor: "#ccc",
    borderWidth: 1,
    marginBottom: 16,
    paddingHorizontal: 10,
    backgroundColor: "#fff",
  },
  button: {
    backgroundColor: "#4CAF50",
    padding: 10,
    borderRadius: 8,
    alignItems: "center",
  },
  disabledButton: {
    backgroundColor: "#ccc",
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  overlay: {
    flex: 1,
    justifyContent: "flex-end",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: 20,
  },
  overlayText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: 8,
  },
  closeIconContainer: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  loadingOverlay: {
    ...StyleSheet.absoluteFill,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  bottomModal: {
    alignSelf: "stretch",
    marginTop: "auto",
  },
});

export default AuthScreen;
