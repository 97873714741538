import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { languages } from "../utils/constants";
import { useSettings } from "../contexts/SettingsContext";
import { useTranslation } from "react-i18next";
import { customLogEvent, logScreenView } from "../utils/analyticsHelpers";

const SettingsScreen = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const navigation = useNavigation();
  const { settings, updateSetting } = useSettings();
  const { t, i18n } = useTranslation();

  const isInitialSelection = navigation.getState().index === 0;

  useEffect(() => {
    // Log analytics event
    if (isInitialSelection) {
      logScreenView("InitialSettings");
    } else {
      logScreenView("Settings");
    }

    // Setting selected language
    if (settings.language) {
      setSelectedLanguage(settings.language);
    }
  }, []);

  const handleLanguageSelection = (languageCode) => {
    setSelectedLanguage(languageCode);
    // updateSetting("locale", locale);
    updateSetting("language", languageCode);
    i18n.changeLanguage(languageCode);
    customLogEvent("language_changed", {
      languageCode: languageCode,
    });
    console.log("Selected language", languageCode);
    // Check if language selection is the first screen.
    // This happens when user visits app for the first time
    if (isInitialSelection) {
      navigation.replace("Login");
    }
  };

  const goToPrivacyPolicy = () => {
    navigation.navigate("PrivacyPolicy");
  };
  const goToTermsOfService = () => {
    navigation.navigate("TermsOfService");
  };

  return (
    <View style={styles.container}>
      <Image
        source={require("../../assets/cropsea-logo.png")}
        style={styles.logoImage}
      />
      <View style={styles.innerContainer}>
        <Text style={styles.header}>{t("settings.selectLanguage")}</Text>
        <Text style={styles.subheading}>
          {t("settings.changeLanguageCaption")}
        </Text>
        <View style={styles.languageContainer}>
          {languages.map((language) => (
            <TouchableOpacity
              key={language.languageCode}
              style={[
                styles.languageButton,
                selectedLanguage === language.languageCode &&
                  styles.selectedButton,
              ]}
              // style={styles.languageButton}
              onPress={() => handleLanguageSelection(language.languageCode)}
            >
              <Text style={styles.languageButtonText}>
                {language.localName}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
      {!isInitialSelection && (
        <View style={styles.mobileBottomLinksContainer}>
          <TouchableOpacity
            onPress={goToPrivacyPolicy}
            style={styles.mobileBottomLink}
          >
            <Text style={styles.linkText}>{t("settings.privacyPolicy")}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={goToTermsOfService}
            style={styles.mobileBottomLink}
          >
            <Text style={styles.linkText}>{t("settings.termsOfService")}</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    // justifyContent: 'space-between',
    alignItems: "center",
    backgroundColor: "#fff",
    position: "relative",
  },
  innerContainer: {
    maxWidth: 800,
    marginTop: 50,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  subheading: {
    fontSize: 14,
    color: "#555",
    marginBottom: 10,
    textAlign: "center",
  },
  languageContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Allow content to wrap into the next line
    justifyContent: "center",
  },
  languageButton: {
    padding: 15,
    margin: 10,
    backgroundColor: "#ffffff",
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 6,
  },
  selectedButton: {
    backgroundColor: "#daf0da",
  },
  languageButtonText: {
    color: "black",
    fontSize: 18,
    fontWeight: "bold",
  },
  logoImage: {
    marginTop: 50,
    width: 350,
    height: 100,
    resizeMode: "contain",
  },
  mobileBottomLinksContainer: {
    flexDirection: "row",
    marginTop: 20,
    position: "absolute",
    width: "100%",
    bottom: 0, // Align to the bottom
  },
  mobileBottomLink: {
    flex: 1,
    padding: 15,
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderBlockColor: "#5D8D2E",
    alignItems: "center",
    justifyContent: "center",
  },
  linkText: {
    color: "#5D8D2E",
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default SettingsScreen;
