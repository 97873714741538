import { useState } from "react";
import { Animated, TouchableOpacity, Platform, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";

const VoiceInputButton = ({ isRecording, onToggleRecording }) => {
  let recordingLimitTimerId;
  const [scale] = useState(new Animated.Value(1));

  // const handlePress = async () => {
  //   console.log("Maaiz in handlePress");
  //   if (Platform.OS === "web") {
  //     // Handle onPress for web
  //     if (isRecording) {
  //       // Reset the scale animation with a spring effect
  //       // Animated.spring(scale, {
  //       //   toValue: 1,
  //       //   useNativeDriver: true,
  //       // }).start();

  //       // await onStopRecording();
  //       await onToggleRecording(false); // Stop recording
  //     } else {
  //       // Start the scale animation with a spring effect
  //       // Animated.spring(scale, {
  //       //   toValue: 2,
  //       //   useNativeDriver: true,
  //       // }).start();

  //       // Set a timeout to stop recording after 10 seconds
  //       clearTimeout(recordingLimitTimerId);
  //       // const recording = await onStartRecording();
  //       await onToggleRecording(true); // Start recording
  //     }
  //   }
  // };

  const handleLongPress = async () => {
    console.log("Maaiz in handleLongPress");
    // if (Platform.OS !== "web") {
    // Add any additional logic needed when the button is pressed
    console.log("Button pressed in");

    await onToggleRecording(true);

    // Start the scale animation with a spring effect
    Animated.spring(scale, {
      toValue: 2,
      useNativeDriver: true,
    }).start();
    // }
  };

  const handlePressOut = async () => {
    // if (Platform.OS !== "web") {
    // Reset the scale animation with a spring effect
    Animated.spring(scale, {
      toValue: 1,
      useNativeDriver: true,
    }).start();

    if (isRecording) {
      // StopRecording when the user releases the press
      await onToggleRecording(false);
    }
    // }
  };

  return (
    <Animated.View style={{ transform: [{ scale }] }}>
      <TouchableOpacity
        style={[
          styles.button,
          { backgroundColor: isRecording ? "#c22424" : "#3f8d43" },
        ]}
        // onPress={handlePress}
        // onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        onLongPress={handleLongPress}
      >
        <Ionicons
          name={isRecording ? "mic-off-circle-outline" : "mic-circle-outline"}
          size={40}
          color="white"
        />
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  button: {
    width: 50,
    height: 50,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default VoiceInputButton;
