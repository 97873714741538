import React, { useEffect } from "react";
import { Image, View, Text, StyleSheet, ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";

const LogoImage = () => (
  <Image
    source={require("../../assets/cropsea-logo.png")}
    style={styles.logoImage}
  />
);

const TermsOfServiceScreen = () => {
  const navigation = useNavigation();

  useEffect(() => {
    const isComingDirectly = navigation.getState().index === 0;
    if (isComingDirectly) {
      navigation.setOptions({
        headerLeft: LogoImage,
      });
    }
  });

  return (
    <ScrollView style={styles.container}>
      <View>
        <Text style={styles.header}>Terms of Service</Text>

        <Text style={styles.paragraph}>
          By using the CropSea app (referred to as "the App") provided by
          Grainogreen Agtech Private Ltd (referred to as "the Company"), you
          agree to abide by the following terms and conditions:
        </Text>

        <Text style={styles.sectionHeader}>1. Acceptance of Terms:</Text>
        <Text style={styles.paragraph}>
          You agree to these Terms of Service by using the App. If you do not
          agree with any part of these terms, you may not use the App.
        </Text>

        <Text style={styles.sectionHeader}>2. User Conduct:</Text>
        <Text style={styles.paragraph}>
          You agree not to engage in any activity that may disrupt or interfere
          with the functioning of the App.
        </Text>

        <Text style={styles.sectionHeader}>3. Intellectual Property:</Text>
        <Text style={styles.paragraph}>
          The App and its content are protected by intellectual property laws.
          You may not use, modify, or distribute our content without permission.
        </Text>

        <Text style={styles.sectionHeader}>4. Account Security:</Text>
        <Text style={styles.paragraph}>
          You are responsible for maintaining the security of your account
          credentials. Notify the Company immediately of any unauthorized use.
        </Text>

        <Text style={styles.sectionHeader}>5. Prohibited Activities:</Text>
        <Text style={styles.paragraph}>
          You agree not to engage in any activities that are illegal, violate
          the rights of others, or compromise the security of the App.
        </Text>

        <Text style={styles.sectionHeader}>6. Limitation of Liability:</Text>
        <Text style={styles.paragraph}>
          The Company is not liable for any damages arising from the use or
          inability to use the App.
        </Text>

        <Text style={styles.sectionHeader}>7. Governing Law:</Text>
        <Text style={styles.paragraph}>
          These Terms of Service are governed by the laws of India.
        </Text>

        <Text style={styles.sectionHeader}>8. Dispute Resolution:</Text>
        <Text style={styles.paragraph}>
          Any disputes arising from these terms shall be resolved through
          arbitration in accordance with the rules of arbitration as defined by
          the Indian Arbitration and Conciliation Act, 1996.
        </Text>

        <Text style={styles.sectionHeader}>9. Changes to Terms:</Text>
        <Text style={[styles.paragraph, styles.lastParagraph]}>
          The Company reserves the right to update these Terms of Service. Your
          continued use of the App constitutes acceptance of the updated terms.
        </Text>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  logoImage: {
    width: 100,
    height: 100,
    marginLeft: 10,
    marginTop: 4,
    resizeMode: "contain",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 15,
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 15,
  },
  lastParagraph: {
    marginBottom: 40,
  },
});

export default TermsOfServiceScreen;
