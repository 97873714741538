import { initializeApp } from "firebase/app";
import {
  getAuth,
  initializeAuth,
  getReactNativePersistence,
  browserLocalPersistence,
} from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { getAnalyticsInstance, getAuthInstance } from "../utils/firebaseHelper";

let analytics, auth;

// const firebaseConfig = {
//   apiKey: "AIzaSyAB4M1bStmMoZC8HxCUBlIHXXiSfJvmqRc",
//   authDomain: "cropsea-2023.firebaseapp.com",
//   projectId: "cropsea-2023",
//   storageBucket: "cropsea-2023.appspot.com",
//   messagingSenderId: "614524365114",
//   appId: "1:614524365114:web:c97c426247e2c37d0d0803",
//   measurementId: "G-LMFKB2XGS5",
// };
const firebaseConfig = {
  apiKey: "AIzaSyBRT8reRzmLTrtKEFTl_ctFBImsGSAmjgo",
  authDomain: "cropsea-0923.firebaseapp.com",
  projectId: "cropsea-0923",
  storageBucket: "cropsea-0923.appspot.com",
  messagingSenderId: "977097918568",
  appId: "1:977097918568:web:2bfddee3c0d4f19b8113dd",
  measurementId: "G-91EHJYKG9Z",
};

const app = initializeApp(firebaseConfig);

// Use dynamic import to conditionally import the module
if (Platform.OS === "web") {
  analytics = getAnalyticsInstance(app);
} else {
  analytics = getAnalyticsInstance();
}

// Get AuthInstance based on the platform
auth = getAuthInstance(app);

export { auth, app, analytics };
