import { View, StyleSheet } from "react-native";
import { InputToolbar, Composer, Send } from "react-native-gifted-chat";
import { Ionicons } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";

import VoiceInputButton from "./VoiceInputButton";

const ChatInputToolbar = ({
  isRecording,
  onToggleRecording,
  countdown,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <InputToolbar
      {...props}
      containerStyle={styles.container}
      renderComposer={() => (
        <Composer
          {...props}
          placeholder={
            isRecording
              ? `${t("chat.listening")} ${countdown}s`
              : t("chat.messageComposerPlaceholder")
          }
          textInputStyle={{
            color: "black",
            fontSize: 18,
          }}
          multiline={true}
        />
      )}
      renderSend={() => (
        <Send {...props} label="Send" containerStyle={styles.send}>
          <Ionicons name="send" size={24} color="green" />
        </Send>
      )}
      // renderAccessory={() => (
      //   <View style={styles.accessory}>
      //     <VoiceInputButton
      //       isRecording={isRecording}
      //       onToggleRecording={onToggleRecording}
      //     />
      //   </View>
      // )}
      renderActions={() => (
        <View>
          <VoiceInputButton
            isRecording={isRecording}
            onToggleRecording={onToggleRecording}
          />
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 30,
    marginHorizontal: 10,
    marginBottom: 12,
    borderWidth: 2,
    borderColor: "#ccc",
  },
  accessory: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  // action: {
  //   flexDirection: "row", // Change to 'row' for right-aligned actions
  //   alignItems: "center",
  //   justifyContent: "flex-end", // Change to 'flex-end' for right alignment
  // },
  // action: { position: "absolute", right: 10 },
  send: {
    alignItems: "center",
    paddingBottom: 9,
    paddingRight: 10,
  },
});

export default ChatInputToolbar;
