import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { v4 as uuid } from "uuid";
import { Platform } from "react-native";

import { BOT_NAME, STORAGE_KEYS, URLS } from "../utils/constants";
import { getAccessToken, getUserIdFromCache } from "../utils/helpers";
import {
  customLogEvent,
  logApiRequest,
  logApiError,
} from "../utils/analyticsHelpers";

const userAvatar = require("../../assets/farmer2.jpeg");
const botAvatar = require("../../assets/bot.png");

/**
 * Function fetch messages from backend
 */
export const fetchMessagesFromBackend = async () => {
  let response;
  try {
    const userId = await getUserIdFromCache();
    const accessToken = await getAccessToken();
    const endpoint = `${URLS.MESSAGES}/user/${userId}/conversations`;

    await logApiRequest(endpoint, "GET", "fetch_messages");

    response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const { messages } = response.data;

    return messages;
  } catch (error) {
    console.error("Error fetching messages from the backend:", error);
    await logApiError(
      `${URLS.MESSAGES}/user/${userId}/conversations`,
      response?.status,
      error.message,
      "fetch_messages"
    );
    // Handle the error as needed
    return [];
  }
};

// Function to save messages to the backend
export const saveMessagesToBackend = async (messages) => {
  let response;
  try {
    const accessToken = await getAccessToken();
    const endpoint = URLS.MESSAGES;

    logApiRequest(endpoint, "POST", "save_messages");

    response = await axios.post(endpoint, messages, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const responseData = response.data;

    console.log("Messages saved successfully:", responseData);
    return responseData;
  } catch (error) {
    console.error("Error saving messages to the backend:", error);
    logApiError(
      URLS.MESSAGES,
      response?.status,
      error.message,
      "save_messages"
    );
    // Handle the error as needed
  }
};

export const submitAudio = async (apiUrl, data) => {
  let response;
  try {
    const formData = new FormData();
    const accessToken = await getAccessToken();

    if (Platform.OS === "web") {
      const audioFromUri = await fetch(data);
      const blob = await audioFromUri.blob();
      formData.append("audio", blob, "voice_message.webm");
    } else {
      formData.append("audio", {
        uri: data,
        type: "audio/webm",
        name: "voice_message.webm",
      });
    }

    logApiRequest(apiUrl, "POST", "submit_audio");

    response = await axios.post(apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response;
  } catch (error) {
    console.error("Error submitting audio:", error);
    logApiError(apiUrl, response?.status, error.message, "submit_audio");
    throw error; // Rethrow the error to propagate it to the caller
  }
};

export const submitText = async (apiUrl, data) => {
  let response;
  try {
    const accessToken = await getAccessToken();

    logApiRequest(apiUrl, "POST", "submit_text");

    response = await axios.post(
      apiUrl,
      { text: data.text },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error submitting text:", error);
    logApiError(apiUrl, response?.status, error.message, "submit_text");
    throw error; // Rethrow the error to propagate it to the caller
  }
};

// TODO: Somehow populate userName in the backend
export const createMessage = (data, messageData, userName, isBot = false) => {
  const message = {
    _id: messageData?._id || uuid(),
    ...(data.text && { text: data.text }),
    createdAt: messageData?.createdAt || new Date(),
    ...(data.audio_uri && { audio: data.audio_uri }),
    ...(isBot
      ? {
          user: {
            name: BOT_NAME,
          },
        }
      : {
          user: {
            name: userName, // Assuming userName is defined in the scope
          },
        }),
  };

  return message;
};

export const addAvatarToMessages = (messages) => {
  // If messages is empty array, return
  if (Array.isArray(messages) && messages.length === 0) {
    return messages;
  }
  const updatedMessages = messages.map((message) => {
    const isBot = message.user && message.user.name === BOT_NAME;

    return {
      ...message,
      user: {
        ...message.user,
        avatar: isBot ? botAvatar : userAvatar,
      },
    };
  });

  return updatedMessages;
};

// export const createMessage = (data, messageData, isBot = false) => {
//   const newMessage = {
//     _id: messageData._id || uuid(),
//     createdAt: messageData.createdAt || new Date(),
//     ...(data.text && { text: data.text }),
//     ...(data.audio_uri && { audio: data.audio_uri }),
//     ...(isBot && {
//       user: {
//         _id: userId, // Make sure userId is defined
//         name: userName, // Make sure userName is defined
//       },
//     }),
//   };

//   return { ...messageData, ...newMessage };
// };

// Function to load messages from AsyncStorage
export const loadMessagesFromCache = async () => {
  try {
    const accessToken = await getAccessToken();
    // Retrieve stored messages from AsyncStorage
    const storedMessages = await AsyncStorage.getItem(STORAGE_KEYS.MESSAGES);

    if (storedMessages) {
      // Parse the stored messages
      const parsedMessages = JSON.parse(storedMessages);
      return parsedMessages;
    }

    // If there are no stored messages, return an empty array or handle as needed
    return [];
  } catch (error) {
    console.error("Error loading messages:", error);
    // Handle the error as needed
    return [];
  }
};

// Function to store messages in AsyncStorage
export const storeMessagesInCache = async (messages) => {
  try {
    const accessToken = await getAccessToken();
    // Get the existing messages from AsyncStorage (if any)
    const existingMessages = await AsyncStorage.getItem(STORAGE_KEYS.MESSAGES);
    const parsedExistingMessages = existingMessages
      ? JSON.parse(existingMessages)
      : [];

    // Concatenate the new messages with the existing ones
    const updatedMessages = [...parsedExistingMessages, ...messages];

    // Save only the last 10 messages
    const last10Messages = updatedMessages.slice(-10);

    // Store the updated messages in AsyncStorage
    await AsyncStorage.setItem(
      STORAGE_KEYS.MESSAGES,
      JSON.stringify(last10Messages)
    );
  } catch (error) {
    console.error("Error storing messages:", error);
  }
};

export const initialHiMessage = (t) => [
  {
    _id: 1,
    text: t("chat.sayHi"),
    createdAt: new Date(),
    user: {
      name: BOT_NAME,
      avatar: botAvatar,
    },
  },
];
