import * as Location from "expo-location";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { v4 as uuid } from "uuid";

import { URLS, STORAGE_KEYS } from "../utils/constants";
import { getAccessToken } from "../utils/helpers";

// Function to collect the location and send it to the backend
export const collectAndSendLocation = async () => {
  try {
    // Check if location data is already in AsyncStorage
    const storedLocation = await AsyncStorage.getItem(
      STORAGE_KEYS.USER_LOCATION
    );

    if (storedLocation) {
      console.log("Maaiz current location:", storedLocation);
      // Location data is already present, no need to collect again
      console.log("Location data already exists in AsyncStorage");
      return;
    }

    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      console.log("Permission to access location was denied");
      return;
    }

    const location = await Location.getCurrentPositionAsync({});
    const { latitude, longitude } = location.coords;

    console.log("Maaiz latitude, longitude:", latitude, longitude);
    const accessToken = await getAccessToken();
    // Make a backend call to save location
    await axios.post(
      URLS.LOCATIONS,
      {
        latitude,
        longitude,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Store the location data in AsyncStorage
    await AsyncStorage.setItem(
      STORAGE_KEYS.USER_LOCATION,
      JSON.stringify({ latitude, longitude })
    );

    console.log("Location data sent to the backend");
  } catch (error) {
    console.error("Error collecting and sending location:", error);
  }
};

export const removeLocationFromCache = async () => {
  await AsyncStorage.removeItem(STORAGE_KEYS.USER_LOCATION);
};
