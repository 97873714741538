import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Localization from "expo-localization";
// https://stackoverflow.com/a/70865185/3338348
import "intl-pluralrules";

import translations from "../../assets/locales/translations.json";
import { getLanguageFromCachedSettings } from "../utils/helpers";

const languageDetectorPlugin = {
  type: "languageDetector",
  async: true,
  init: () => {},
  detect: async function (callback) {
    try {
      const language = await getLanguageFromCachedSettings();
      console.log("Maaiz in languageDetectorPlugin", language);
      if (language) {
        return callback(language);
      } else {
        console.log(
          "Maaiz in languageDetectorPlugin else ",
          language,
          Localization.getLocales()[0].languageCode
        );
        return callback(Localization.getLocales()[0].languageCode);
      }
    } catch (error) {
      console.log("Error reading language", error);
    }
  },
};

const initI18n = async () => {
  await i18n.use(initReactI18next).use(languageDetectorPlugin).init({
    resources: translations,
    fallbackLng: "en",
    initImmediate: false,
  });
};

// Export an asynchronous function that initializes i18n before exporting
export const initializeI18n = async () => {
  await initI18n();
  return i18n;
};
