import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from "react-native";
import { GiftedChat, Bubble, Time, Avatar } from "react-native-gifted-chat";
import { Audio } from "expo-av";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import "react-native-get-random-values";
import { useTranslation } from "react-i18next";

import { useSettings } from "../contexts/SettingsContext";
import {
  getAccessToken,
  getLocaleFromLanguageCode,
  getUserIdFromCache,
  getUserNameFromCache,
} from "../utils/helpers";
import {
  BOT_NAME,
  RECORDING_LIMIT,
  STORAGE_KEYS,
  URLS,
  dayJsLocaleimport,
} from "../utils/constants";
import AudioPlayer from "../components/AudioPlayer";
import ChatInputToolbar from "../components/ChatInputToolbar";
import {
  addAvatarToMessages,
  createMessage,
  fetchMessagesFromBackend,
  saveMessagesToBackend,
  submitAudio,
  submitText,
  initialHiMessage,
} from "../services/chatService";
import { collectAndSendLocation } from "../services/localtionService";
import { logScreenView } from "../utils/analyticsHelpers";
// import { sendAnalyticsEventAsync } from "../services/firebase";

const ChatScreen = (props) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recording, setRecording] = useState(null);
  const [dayJsLocale, setDayJsLocale] = useState({});
  const navigation = useNavigation();
  const { settings } = useSettings();
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [loadingMessage, setLoadingMessage] = useState(false);
  const locale = getLocaleFromLanguageCode(settings.language);

  const [countdown, setCountdown] = useState(RECORDING_LIMIT); // Initial countdown value

  useEffect(() => {
    // Log analytics event
    logScreenView("ChatScreen");

    const initialLoad = async () => {
      // await sendAnalyticsEventAsync();
      const userId = await getUserIdFromCache();
      const userName = await getUserNameFromCache();
      setUserName(userName);
      setUserId(userId);
      // Call the function to fetch messages when the component mounts
      const fetchedMessages = await fetchMessagesFromBackend();
      const messagesWithAvatar = addAvatarToMessages(fetchedMessages);

      if (messagesWithAvatar.length === 0) {
        setMessages(initialHiMessage(t));
      } else {
        setMessages(messagesWithAvatar);
      }
    };

    initialLoad();

    // setTimeout(() => {
    //   const messageWithoutAvatar = sampleMessages(t);
    //   const messageWithAvatar = addAvatarToMessages(messageWithoutAvatar);
    //   // setMessages(messageWithAvatar);
    // }, 2000);
  }, []);

  // FIXME: Optimize it to be called only once when login happens
  useEffect(() => {
    // Collect the location when the user first visits the app
    collectAndSendLocation();
  }, []);

  useEffect(() => {
    let countdownInterval;

    if (isRecording) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          if (newCountdown === 0) {
            handleStopRecording(recording); // Stop recording when the timer reaches 0
            clearInterval(countdownInterval); // Clear the interval
          }
          return newCountdown;
        });
      }, 1000); // Update countdown every second
    } else {
      setCountdown(RECORDING_LIMIT);
    }

    return () => {
      clearInterval(countdownInterval); // Cleanup on unmount or when recording stops
    };
  }, [isRecording]);

  useEffect(() => {
    try {
      const dayJsLocaleName = settings.language;
      const localeModule = dayJsLocaleimport[dayJsLocaleName];

      setDayJsLocale(localeModule);
    } catch (error) {
      console.error("Error requiring dayjs locale:", error);
      // Fallback to the default locale (e.g., English)
      const defaultLocaleModule = dayJsLocaleimport["en"];
      setDayJsLocale(defaultLocaleModule);
    }
  }, [settings.language]);

  useEffect(() => {
    // Customizing navigation bar
    navigation.setOptions(
      {
        headerRight: () => (
          <TouchableOpacity
            style={{ marginRight: 10 }}
            onPress={() => navigation.navigate("Settings")}
          >
            <Ionicons name="settings-sharp" size={30} color="black" />
          </TouchableOpacity>
        ),
      },
      []
    );

    // For web on the initial load chat messages were not loading
    // The code is a hack to display the messages
    // Reference: https://github.com/FaridSafi/react-native-gifted-chat/issues/2448
    if (Platform.OS === "web") {
      const gcLoadingContainerEl = document.querySelectorAll(
        '[data-testid="GC_LOADING_CONTAINER"]'
      )[0];
      if (gcLoadingContainerEl) {
        gcLoadingContainerEl.style.display = "none";
        setTimeout(() => {
          gcLoadingContainerEl.style.display = "flex";
        }, 500);
      }
    }
  }, []);

  // Function to handle sending messages
  const onSend = async (newMessages = []) => {
    // Assuming the text is in the first message of newMessages array
    const lastNewMessage = newMessages[0];

    // Call submitData with the message text
    if (lastNewMessage) {
      await submitData(lastNewMessage, "text");
    }
    // if (lastNewMessage) {
    //   await saveMessageSample(lastNewMessage.text, "text");
    // }
  };

  const handleToggleRecording = async (startRecording) => {
    if (startRecording) {
      return await handleStartRecording();
    } else {
      return await handleStopRecording();
    }
  };

  // Function to start recording
  async function handleStartRecording() {
    console.log("Recording started");
    try {
      console.log("Requesting permissions..");
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      const { recording, status } = await Audio.Recording.createAsync(
        Audio.RecordingOptionsPresets.HIGH_QUALITY
      );
      console.log("Maaiz Starting recording..", recording);
      setRecording(recording);
      setIsRecording(true);
    } catch (err) {
      console.error("Failed to start recording", err);
    }
  }

  // Function to stop recording
  async function handleStopRecording() {
    try {
      await recording.stopAndUnloadAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
      });
      const uri = recording.getURI();
      console.log("Recording stopped and stored at", uri);
      setIsRecording(false);
      setRecording(null);
      // setUri(uri);
      await submitData(uri, "audio");
    } catch (error) {
      console.log("Maaiz in stopRecording error", error);
    }
  }

  // const submitData = async (data, dataType) => {
  //   try {
  //     const accessToken = await getAccessToken();
  //     const apiUrl = `${URLS.PROCESS_INPUT}?locale=${locale}`;
  //     let response;

  //     setLoadingMessage(true);

  //     if (dataType === "audio") {
  //       // For audio data, fetch the blob of the file (web) or use the uri directly (mobile)
  //       const formData = new FormData();

  //       if (Platform.OS === "web") {
  //         const audioFromUri = await fetch(data);
  //         const blob = await audioFromUri.blob();
  //         formData.append("audio", blob, "voice_message.webm");
  //       } else {
  //         formData.append("audio", {
  //           uri: data,
  //           type: "audio/webm", // Adjust the MIME type based on your audio format
  //           name: "voice_message.webm",
  //         });
  //       }

  //       response = await axios.post(apiUrl, formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });

  //       console.log("Audio uploaded successfully:", response.data);

  //       // Handle the response as needed for audio
  //     } else if (dataType === "text") {
  //       console.log("Submitting text data:", data);
  //       // For text data, send it as JSON in the request body
  //       response = await axios.post(
  //         apiUrl,
  //         { text: data },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       );

  //       console.log("Text data uploaded successfully:", response.data);
  //     } else {
  //       // Handle unknown data type
  //       console.error("Unknown data type:", dataType);
  //       return;
  //     }

  //     // Extract user and bot messages from the response
  //     const { user, bot } = response.data;

  //     // Handle the response as needed for text
  //     const newUserMessage = {
  //       _id: uuid(),
  //       text: user.text,
  //       createdAt: new Date(),
  //       user: {
  //         _id: 1,
  //         name: "Maaiz",
  //         avatar: require("../../assets/maaiz-profile.jpeg"),
  //       },
  //       audio: user.audio_uri,
  //     };
  //     const newBotMessage = {
  //       _id: uuid(),
  //       text: bot.text,
  //       createdAt: new Date(),
  //       user: {
  //         _id: 2,
  //         name: "CropGenie",
  //         avatar:
  //           "https://images.unsplash.com/photo-1511367461989-f85a21fda167?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D",
  //       },
  //       audio: bot.audio_uri,
  //     };

  //     // Update the state with new messages
  //     setMessages((previousMessages) =>
  //       GiftedChat.append(previousMessages, newUserMessage)
  //     );
  //     setMessages((previousMessages) =>
  //       GiftedChat.append(previousMessages, newBotMessage)
  //     );
  //   } catch (error) {
  //     console.error("Failed to upload data:", error);
  //   } finally {
  //     setLoadingMessage(false);
  //   }
  // };

  const submitData = async (messageData, dataType) => {
    try {
      setLoadingMessage(true);

      const apiUrl = `${URLS.PROCESS_INPUT}?locale=${locale}`;
      let response;

      if (dataType === "audio") {
        response = await submitAudio(apiUrl, messageData);
      } else if (dataType === "text") {
        response = await submitText(apiUrl, messageData);
      } else {
        console.error("Unknown data type:", dataType);
        return;
      }

      const { user, bot } = response.data;

      const newUserMessage = createMessage(user, messageData, userName);
      const newBotMessage = createMessage(bot, null, null, true);

      // Call saveMessagesToBackend before updating messages
      const messagesFromBackend = await saveMessagesToBackend([
        newUserMessage,
        newBotMessage,
      ]);

      // Setting bot message to automatically play
      messagesFromBackend[1].shouldPlay = true;

      const messagesWithAvatar = addAvatarToMessages(messagesFromBackend);

      // Reversing it as it is not displaying the messages in the right order
      setMessages((previousMessages) => {
        return GiftedChat.append(
          previousMessages,
          messagesWithAvatar.reverse()
        );
      });
    } catch (error) {
      console.error("Failed to upload data:", error);
    } finally {
      setLoadingMessage(false);
    }
  };

  const saveMessageSample = async (text) => {
    const messageUser = createMessage({ text: text }, null, userName);
    const messageBot = createMessage({ text: text }, null, null, true);

    const messagesFromBackend = await saveMessagesToBackend([
      messageUser,
      messageBot,
    ]);
    const userId = await getUserIdFromCache();
    console.log("Maaiz in saveMessageSample userId", userId);

    setMessages((previousMessages) => {
      return GiftedChat.append(previousMessages, messagesFromBackend.reverse());
    });
  };

  const renderInputToolbar = (props) => (
    <ChatInputToolbar
      {...props}
      isRecording={isRecording}
      onToggleRecording={handleToggleRecording}
      countdown={countdown}
    />
  );

  const renderMessageAudio = (props) => {
    const { currentMessage } = props;
    return (
      <AudioPlayer
        audioUri={currentMessage.audio}
        shouldPlay={currentMessage.shouldPlay}
      />
    );
  };

  const renderBubble = (props) => {
    return (
      <Bubble
        {...props}
        textStyle={{
          right: {
            color: "#345e37",
            paddingTop: 15,
          },
          left: {
            paddingTop: 15,
          },
        }}
        wrapperStyle={{
          right: {
            backgroundColor: "#daf0da",
            width: "70%",
          },
          left: {
            // backgroundColor: "#f5f5dc",
            width: "70%",
          },
        }}
      ></Bubble>
    );
  };

  const renderTime = (props) => {
    return (
      <Time
        {...props}
        position="right"
        timeTextStyle={{
          right: {
            color: "#345e37",
          },
          left: {
            color: "#555555",
            textAlign: "right",
          },
        }}
      />
    );
  };

  const renderLoading = () => (
    <View style={styles.loadingOverlay}>
      <LoadingIndicator />
    </View>
  );

  // const renderAvatar = (props) => {
  //   const { currentMessage } = props;
  //   const isBot = currentMessage.user && currentMessage.user.name === BOT_NAME;

  //   // Use the appropriate avatar based on the user (farmer or bot)
  //   const avatarSource = isBot ? botAvatar : userAvatar;

  //   return <Avatar {...props} source={avatarSource} />;
  // };

  const renderChatFooter = () => {
    return <View style={{ height: 40 }}></View>;
  };

  const LoadingIndicator = () => (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <ActivityIndicator size="large" color="#fff" />
    </View>
  );

  const handleSend = (newMessages) => {
    const userMessage = newMessages[0];
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, userMessage)
    );

    // Simulate server response delay
    setLoadingMessage(true);
    setTimeout(() => {
      const systemResponse = {
        _id: Math.round(Math.random() * 1000000),
        text: "Sure, here is the information you requested.",
        createdAt: new Date(),
        user: {
          _id: 2,
          name: "System",
          avatar:
            "https://images.unsplash.com/photo-1511367461989-f85a21fda167?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D",
        },
      };

      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, systemResponse)
      );
      setLoadingMessage(false);
    }, 2000); // Simulating a delay of 2 seconds for the server response
  };

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <GiftedChat
          messages={messages}
          onSend={(newMessages) => onSend(newMessages)}
          // onSend={(newMessages) => handleSend(newMessages)}
          renderLoading={renderLoading}
          renderMessageAudio={renderMessageAudio}
          renderBubble={renderBubble.bind(this)}
          renderTime={renderTime}
          user={{
            _id: userId,
          }}
          renderInputToolbar={renderInputToolbar}
          showUserAvatar={true}
          showAvatarForEveryMessage={true}
          renderChatFooter={renderChatFooter}
          locale={dayJsLocale}
          dateFormat="LL"
          timeFormat="HH:mm" // Default is LT
          alwaysShowSend={true}
        />

        {/* Loading Overlay */}
        {loadingMessage && (
          <View style={styles.loadingOverlay}>
            <LoadingIndicator />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#eee",
  },
  innerContainer: {
    flex: 1,
    maxWidth: 800,
    width: "100%",
    backgroundColor: "#fff",
    elevation: 5, // For Android
    shadowColor: "black", // For iOS
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
  },
  // innerContainer: {
  //   maxWidth: 600,
  //   width: "100%",
  // },
  loadingOverlay: {
    ...StyleSheet.absoluteFill,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
});

export default ChatScreen;
