import React from "react";
import {
  ActivityIndicator,
  Button,
  Modal,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from "react-native";

import FirebaseRecaptcha from ".";

class FirebaseRecaptchaVerifierModal extends React.Component {
  static defaultProps = {
    title: "reCAPTCHA",
    cancelLabel: "Cancel",
  };

  state = {
    visible: false,
    visibleLoaded: false,
    invisibleLoaded: false,
    invisibleVerify: false,
    invisibleKey: 1,
    resolve: undefined,
    reject: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.attemptInvisibleVerification && state.invisibleLoaded) {
      return {
        invisibleLoaded: false,
        invisibleVerify: false,
      };
    }
    return null;
  }

  get type() {
    return "recaptcha";
  }

  async verify() {
    return new Promise((resolve, reject) => {
      if (this.props.attemptInvisibleVerification) {
        this.setState({
          invisibleVerify: true,
          resolve,
          reject,
        });
      } else {
        this.setState({
          visible: true,
          visibleLoaded: false,
          resolve,
          reject,
        });
      }
    });
  }

  _reset(...args) {}

  onVisibleLoad = () => {
    this.setState({
      visibleLoaded: true,
    });
  };

  onInvisibleLoad = () => {
    this.setState({
      invisibleLoaded: true,
    });
  };

  onFullChallenge = async () => {
    this.setState({
      invisibleVerify: false,
      visible: true,
    });
  };

  onError = () => {
    const { reject } = this.state;
    if (reject) {
      const err = new Error("Failed to load reCAPTCHA");
      err.code = "ERR_FIREBASE_RECAPTCHA_ERROR";
      reject(err);
    }
    this.setState({
      visible: false,
      invisibleVerify: false,
    });
  };

  onVerify = (token) => {
    const { resolve } = this.state;
    if (resolve) {
      resolve(token);
    }
    this.setState((state) => ({
      visible: false,
      invisibleVerify: false,
      invisibleLoaded: false,
      invisibleKey: state.invisibleKey + 1,
    }));
  };

  cancel = () => {
    const { reject } = this.state;
    if (reject) {
      const err = new Error("Cancelled by user");
      err.code = "ERR_FIREBASE_RECAPTCHA_CANCEL";
      reject(err);
    }
    this.setState({
      visible: false,
    });
  };

  onDismiss = () => {
    if (this.state.visible) {
      this.cancel();
    }
  };

  render() {
    const { title, cancelLabel, attemptInvisibleVerification, ...otherProps } =
      this.props;
    const {
      visible,
      visibleLoaded,
      invisibleLoaded,
      invisibleVerify,
      invisibleKey,
    } = this.state;
    return (
      <View style={styles.container}>
        {attemptInvisibleVerification && (
          <FirebaseRecaptcha
            {...otherProps}
            key={`invisible${invisibleKey}`}
            style={styles.invisible}
            onLoad={this.onInvisibleLoad}
            onError={this.onError}
            onVerify={this.onVerify}
            onFullChallenge={this.onFullChallenge}
            invisible
            verify={invisibleLoaded && invisibleVerify}
          />
        )}
        <Modal
          visible={visible}
          animationType="slide"
          presentationStyle="pageSheet"
          onRequestClose={this.cancel}
          onDismiss={this.onDismiss}
        >
          <SafeAreaView style={styles.modalContainer}>
            <View style={styles.header}>
              <Text style={styles.title}>{title}</Text>
              <View style={styles.cancel}>
                <Button
                  title={
                    cancelLabel ||
                    FirebaseRecaptchaVerifierModal.defaultProps.cancelLabel
                  }
                  onPress={this.cancel}
                />
              </View>
            </View>
            <View style={styles.content}>
              <FirebaseRecaptcha
                {...otherProps}
                style={styles.content}
                onLoad={this.onVisibleLoad}
                onError={this.onError}
                onVerify={this.onVerify}
              />
              {!visibleLoaded ? (
                <View style={styles.loader}>
                  <ActivityIndicator size="large" />
                </View>
              ) : undefined}
            </View>
          </SafeAreaView>
        </Modal>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 0,
    height: 0,
  },
  invisible: {
    width: 300,
    height: 300,
  },
  modalContainer: {
    flex: 1,
  },
  header: {
    backgroundColor: "#FBFBFB",
    height: 44,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderBottomColor: "#CECECE",
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  cancel: {
    position: "absolute",
    left: 8,
    justifyContent: "center",
  },
  title: {
    fontWeight: "bold",
  },
  content: {
    flex: 1,
  },
  loader: {
    ...StyleSheet.absoluteFillObject,
    paddingTop: 20,
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default FirebaseRecaptchaVerifierModal;
