export const BASE_URL = "https://backend.cropsea.com";
// export const BASE_URL =
//   "https://71dd-2409-40e3-100d-4560-146f-33bd-b2ea-d91c.ngrok-free.app";
// export const BASE_URL = "http://localhost:3000";
// 10.0.2.2
const API_VERSION = "v1";

export const RECORDING_LIMIT = 10; // Recording time limit in seconds

export const languages = [
  {
    name: "English",
    localName: "English",
    locale: "en-IN",
    languageCode: "en",
  },
  { name: "Hindi", localName: "हिन्दी", locale: "hi-IN", languageCode: "hi" },
  { name: "Bengali", localName: "বাঙালি", locale: "bn-IN", languageCode: "bn" },
  {
    name: "Gujarati",
    localName: "ગુજરાતી",
    locale: "gu-IN",
    languageCode: "gu",
  },
  { name: "Kannada", localName: "ಕನ್ನಡ", locale: "kn-IN", languageCode: "kn" },
  {
    name: "Malayalam",
    localName: "മലയാളം",
    locale: "ml-IN",
    languageCode: "ml",
  },
  { name: "Marathi", localName: "मराठी", locale: "mr-IN", languageCode: "mr" },
  { name: "Punjabi", localName: "ਪੰਜਾਬੀ", locale: "pa-IN", languageCode: "pa" },
  { name: "Tamil", localName: "தமிழ்", locale: "ta-IN", languageCode: "ta" },
  { name: "Telugu", localName: "తెలుగు", locale: "te-IN", languageCode: "te" },

  // { name: "Odia", localName: "ଓଡ଼ିଆ", locale: "or-IN", languageCode: "or" },
  // {
  //   name: "Assamese",
  //   localName: "অসমীয়া",
  //   locale: "as-IN",
  //   languageCode: "as",
  // },
  // { name: "Urdu", localName: "اردو", locale: "ur-IN", languageCode: "ur" },
  // { name: "Maithili", localName: "मैथिली", locale: "mai-IN", languageCode: "mai" },
  // { name: "Santali", localName: "ᱥᱟᱱᱛᱟᱲᱤ", locale: "sat-IN", languageCode: "sat" },
  // { name: "Kashmiri", localName: "كٲشُر", locale: "ks-IN", languageCode: "ks" },
  // { name: "Nepali", localName: "नेपाली", locale: "ne-IN", languageCode: "ne" },
  // { name: "Konkani", localName: "कोंकणी", locale: "kok-IN", languageCode: "kok" },
  // { name: "Sindhi", localName: "سنڌي", locale: "sd-IN", languageCode: "sd" },
  // { name: "Dogri", localName: "डोगरी", locale: "doi-IN", languageCode: "doi" },
];

export const URLS = {
  USERS: `${BASE_URL}/api/${API_VERSION}/users`,
  LOCATIONS: `${BASE_URL}/api/${API_VERSION}/locations`,
  AUDIO: `${BASE_URL}/api/${API_VERSION}/audio`,
  PROCESS_INPUT: `${BASE_URL}/api/${API_VERSION}/chat/process-input`,
  MESSAGES: `${BASE_URL}/api/${API_VERSION}/messages`,
};

// Constants for storage keys
const APP_PREFIX = "cropsea";

export const STORAGE_KEYS = {
  USER_NAME: APP_PREFIX + "UserName",
  USER_ID: APP_PREFIX + "UserID",
  APP_SETTINGS: APP_PREFIX + "AppSettings",
  ACCESS_TOKEN: APP_PREFIX + "AccessToken",
  USER_LOCATION: APP_PREFIX + "UserLocation",
  MESSAGES: APP_PREFIX + "Messages",
};

// Dynamic imports using variable don't work in react-native
export const dayJsLocaleimport = {
  en: require("dayjs/locale/en-in"),
  hi: require("dayjs/locale/hi"),
  bn: require("dayjs/locale/bn"),
  gu: require("dayjs/locale/gu"),
  kn: require("dayjs/locale/kn"),
  ml: require("dayjs/locale/ml"),
  mr: require("dayjs/locale/mr"),
  pa: require("dayjs/locale/pa-in"),
  ta: require("dayjs/locale/ta"),
  te: require("dayjs/locale/te"),
};

export const BOT_NAME = "CropSea AI";

export const dummy = "Dummy";

export const ANALYTICS_ENABLED = false;
