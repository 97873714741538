import AsyncStorage from "@react-native-async-storage/async-storage";
import { languages, STORAGE_KEYS } from "./constants";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const getLocaleForBackend = (languageCode) => `${languageCode}-IN`;

export const addCountryCode = (phoneNumber) => {
  // Remove any non-digit characters from the phone number
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Check if the numericPhoneNumber is exactly 10 digits
  if (numericPhoneNumber.length === 10) {
    // If it's 10 digits, add "+91" to the beginning
    return "+91" + numericPhoneNumber;
  } else {
    // If it's not 10 digits, return the original phone number
    return phoneNumber;
  }
};

export const executeWithDelay = (fn, seconds) => {
  // return new Promise((resolve) => {
  // fn(); // Execute the provided function immediately

  // Wait for the specified number of seconds
  setTimeout(() => {
    fn();
    // resolve(); // Resolve the Promise after the delay
  }, seconds * 1000); // Convert seconds to milliseconds
  // });
  return true;
};

export const importData = async () => {
  try {
    const keys = await AsyncStorage.getAllKeys();
    const result = await AsyncStorage.multiGet(keys);

    result.forEach((item) => {
      console.log(item), console.log("\n***********");
    });
    // return result.map((req) => JSON.parse(req)).forEach(console.log);
  } catch (error) {
    console.error(error);
  }
};

export const fetchAllItems = async () => {
  try {
    await AsyncStorage.removeItem("__sak");
    const keys = await AsyncStorage.getAllKeys();
    const items = await AsyncStorage.multiGet(keys);
    AsyncStorage.get;

    return items;
  } catch (error) {
    console.log(error, "problemo");
  }
};

export const getLocaleFromLanguageCode = (languageCode) => {
  const language = languages.find((lang) => lang.languageCode === languageCode);
  return language ? language.locale : null;
};

export const isUserAuthenticated = async () => {
  try {
    const auth = getAuth();

    return new Promise((resolve) => {
      // Listen for authentication state changes
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        // Unsubscribe to avoid memory leaks
        unsubscribe();

        // Resolve with true if user is authenticated, false otherwise
        resolve(!!user);
      });
    });
  } catch (error) {
    console.error("Error checking authentication:", error);
    return false;
  }
};

export const getAccessToken = async () => {
  const accessToken = await AsyncStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
  return accessToken;
};

export const getLanguageFromCachedSettings = async () => {
  let settings = await AsyncStorage.getItem(STORAGE_KEYS.APP_SETTINGS);
  if (settings) {
    settings = JSON.parse(settings);
    if (settings.language) {
      return settings.language;
    }
  }
};

export const getUserIdFromCache = async () => {
  const userId = await AsyncStorage.getItem(STORAGE_KEYS.USER_ID);
  return userId;
};

export const getUserNameFromCache = async () => {
  const userName = await AsyncStorage.getItem(STORAGE_KEYS.USER_NAME);
  return userName;
};
